<template>
  <div class="dailyInterimList">
    <!--搜索条件区域-->
    <div class="searchWrapper" @keydown.enter.prevent="searchData">
      <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
        <el-form-item :label="$t('searchModule.Reconciliation_date')">
          <el-date-picker
            v-model="dateTime"
            type="daterange"
            @change="dateTimeChange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="
            page = 1;
            searchData();
          "
          :loading="loading"
          >查询
        </el-button>
        <el-button type="primary" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
        <el-button
          type="primary"
          icon="el-icon-upload2"
          @click="exportData"
          v-if="$route.meta.authority.button.export"
          >{{ $t('button.export') }}</el-button
        >
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
      >
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  // authority: this.$route.meta.authority,
  name: "dailyInterimList",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    exportFile,
  },
  data() {
    return {
      total: 0,
      page: 1,
      pageSize: 15,
      dateTime: [],
      formInline: {},
      startTime: "",
      endTime: "",
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: this.$t("list.date"),
          prop: "reportDate",
        },
        {
          label: this.$t("list.Merchant_Name"),
          prop: "operationName",
          width: "",
        },
        {
          label: "临停交易金额",
          prop: "revenueMoney",
          formatter: (row) => {
            return (row.revenueMoney / 100).toFixed(2);
          },
        },
        {
          label: this.$t("list.refund_amount"),
          prop: "refundMoney",
          formatter: (row) => {
            return (row.refundMoney / 100).toFixed(2);
          },
        },
        {
          label: this.$t("list.subtotal"),
          prop: "subTotalMoney",
          formatter: (row) => {
            return (row.subTotalMoney / 100).toFixed(2);
          },
        },
      ],
    };
  },
  methods: {
    dateTimeChange(val) {
      this.formInline.startDate = val[0];
      this.formInline.endDate = val[1];
    },
    exportData() {
      exportExcelNew("/acb/2.0/financeDailyReport/export", this.formInline);
    },
    resetForm() {
      let startTime = new Date();
      let endTime = new Date();
      startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 31);
      endTime.setTime(endTime.getTime() - 1000 * 60 * 60 * 24);
      this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
      this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
      this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    validateTime() {
      let diff =
        new Date(this.dateTime[1]).getTime() / (1000 * 3600 * 24) -
        new Date(this.dateTime[0]).getTime() / (1000 * 3600 * 24);
      if (diff > 30) {
        this.$alert("最多查询31天数据");
        return false;
      } else {
        return true;
      }
    },
    searchData() {
      if (!this.validateTime()) return false;
      this.$axios
        .get("/acb/2.0/financeDailyReport/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.value) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        });
    },
  },
  mounted() {
    let startTime = new Date();
    let endTime = new Date();
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60 * 24 * 31);
    endTime.setTime(endTime.getTime() - 1000 * 60 * 60 * 24);
    this.dateTime = [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")];
    this.formInline.startDate = dateFormat(startTime, "yyyy-MM-dd");
    this.formInline.endDate = dateFormat(endTime, "yyyy-MM-dd");
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped rel="stylesheet/stylus">

.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
</style>
